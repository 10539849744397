<template>
  <main class="u-container">
    <header>
      <h1 class="text-base font-bold">
        The Change-of-Address application has experienced an error and your request could not be processed.
      </h1>
      <router-link
        :to="{ name: 'home' }"
        class="c-btn--secondary mt-6 mr-6"
      >
        Exit
      </router-link>
      <router-link
        :to="{ name: 'edit' }"
        class="c-btn mt-3"
      >
        Edit Address
      </router-link>
    </header>
  </main>
</template>

<script>
export default {
  name: 'FatalError',
};
</script>
