<template>
  <main class="u-container">
    <header>
      <h1 class="text-base font-bold mt-6">
        An error has occurred while processing your Change-of-Address request.
      </h1>
      <p class="mt-6">
        An error occured while processing your Change-of-Address. Your Change-of-Address is NOT complete, but payment was accepted. We have automatically requested a refund on your behalf, but this process may take up to 3 business days.
      </p>
      <p class="mt-6">
        Please click "Start Over" to try again to process a Change-of-Address request.
      </p>
      <router-link
        :to="{ name: 'home' }"
        class="c-btn mt-6"
      >
        Start Over
      </router-link>
    </header>
  </main>
</template>

<script>
export default {
  name: 'ProcessingError',
};
</script>
